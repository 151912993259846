import React from "react";

export const onRenderBody = ({ setHeadComponents }) => {
  setHeadComponents([
    <link
      rel="icon"
      href="/gatsby-icon.png"
      key="favicon"
    />,
  ]);
}; 